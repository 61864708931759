<template>
  <div id="app">


    <div class="logo-container">
      <a href="https://grupoparque.com.br">
        <img src="./assets/logo_parque.svg" alt="Logo" style="width:160px" />
      </a>
    </div>


    <div class="card-container">
      <div v-if="cardRows && cardRows.length" class="cards">
        <CardItem v-for="card in cardRows" :key="card.id" :nome="card.falecido" :capela="card.capela"
          :sepultamento="card.sepultamento" :cardColor="cardColor" :velorio="card.velorio" :areaTipo="card.quadra"
          :areaQuadra="card.letra" :areaNum="card.jazigo" :falecimento="card.falecimento"
          :nascimento="card.nascimento" />
      </div>
      <div v-else>

        Não encontrado óbitos
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />

    </div><br />

    <footer class="footer">
      <div class="footer-content">
        <img src="./assets/LOGOSVG4.svg" alt="Grupo Parque das Flores" style="width: 100px; height: 90px;">
        <p style="color: white; text-align: center;">©2024 Empresa do Grupo Parque -
          Todos os direitos reservados <br />
        </p>
      </div>
    </footer>



  </div>
</template>

<script>
import CardItem from './components/CardItem.vue'
import Contrato from './services/contrato'

export default {
  name: 'App',
  components: {
    CardItem
  },

  data() {
    return {
      cardColor: "#2c3e50",
      cardRows: [
      ],
    };
  },

  mounted() {
    this.lista();
  },

  methods: {

    lista() {


      Contrato.lista(this.search_name, "PARQUE DAS FLORES", this.currentPage, this.pageSize)
        .then(response => {
          if (response.status === 200) {

            this.cardRows = response.data; // Extrair os dados da resposta paginada
          }
        })
        .catch(error => {
          // Lida com erros
          console.error('Erro a', error);
        }).finally(() => {
          // Após o término do download, oculte o diálogo de progresso
          this.loading = false;
        });
    },


  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 40px;
}

.logo-container {
  text-align: center;
}

.cards {
  margin-top: 2rem;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 80vh;
  /* Defina a altura máxima desejada */
  overflow-y: auto;
  /* Adicione uma barra de rolagem vertical, se necessário */
  position: relative;
  z-index: 0;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px
}

.footer {
  background-color: #3a7091;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
